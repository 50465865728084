import React from "react"
import { DefaultHeader } from "components/headers/default-header"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
// import { StaticImage } from "gatsby-plugin-image"
import { config } from "@fortawesome/fontawesome-svg-core"
import { CvBtn } from "components/case_study/cv-btn"
import { StarRates } from "components/case_study/star-rates"
import { FlowNumber } from "components/case_study/flow-number"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faArrowDown,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
config.autoAddCss = false

// キャンペーン概要
const features = [
  { color: "bg-case_blue", title: "拡散度", content: "高い" },
  {
    color: "bg-case_orange",
    title: "抽選方法",
    content: "即時抽選",
  },
  { color: "bg-accent", title: "結果確認方法", content: "DM" },
]

// こんな方におすすめ文言
const recommends = [
  { title: "ツイートを拡散させたい" },
  { title: "クーポンコードの送付で、紙媒体送付の手間を省きたい" },
  { title: "自社のクーポンコードを配布し、販促に繋げたい" },
  { title: "実店舗やECサイトの訪問数を増やしたい" },
]

const InstantWinCouponCampaign = ({ location }) => {
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="Twitterキャンペーン事例・クーポン配布キャンペーン"
          pagepath={location.pathname}
          pagedesc="参加者にクーポンを配布し、来店や購入の促進を目的とするキャンペーンです。"
        />
        <main className="case_study main">
          {/* メインビジュアル */}
          <section className="hero h-auto">
            <div className="inner relative py-5 md:py-8 px-4">
              <h1 className="text-white font-bold md:mb-4 mb-3">
                クーポン配布
                <br className="block md:hidden" />
                キャンペーン
              </h1>
              <p className="text-white md:text-xl text-lg font-bold leading-relaxed">
                参加者にクーポンを配布し、来店や購入の促進を目的とするキャンペーンです。
              </p>
              <div className="fv_img absolute -right-8 top-32">
                <img
                  layout="fullWidth"
                  alt="クーポン配布キャンペーン"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/case_study/mv_instant-win-coupon-campaign.png"
                />
              </div>
            </div>
          </section>
          {/* こんな方におすすめです */}
          <section className="recommend bg-secondary">
            <div className="inner py-6 md:pt-10 md:pb-28 px-4">
              <ul className="flex md:mb-8 mb-6 gap-2">
                {features.map((feature, index) => {
                  return (
                    <li key={index} className="text-center font-bold">
                      <p
                        className={`text-white ${feature.color} py-1 md:px-6 px-1 text-sm md:text-xl`}
                      >
                        {feature.title}
                      </p>
                      <p className="text-sm md:text-xl bg-white py-1 md:px-6 px-3">
                        {feature.content}
                      </p>
                    </li>
                  )
                })}
              </ul>
              <h2 className="text-2xl md:text-3xl text-primary font-bold pb-2 border-b-2 border-primary md:w-3/4 mb-3">
                こんな方におすすめです
              </h2>
              <div className="">
                {recommends.map((recommend, index) => {
                  return (
                    <div key={index} className="flex items-center mb-1">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-primary mr-3"
                      />
                      <p className="font-bold text-text md:text-xl">
                        {recommend.title}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          {/* キャンペーンの特徴 */}
          <section className="campaign-feat bg-white">
            <div className="inner md:py-20 px-4 pt-8 pb-10">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                キャンペーンの特徴
              </h2>
              <div className="flex md:flex-row flex-col md:gap-10 gap-4">
                <div className="flex gap-4 flex-1">
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-case_blue text-white text-center text-sm md:text-base">
                      参加者
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">手軽さ</p>
                      <StarRates rate={5} />
                      <hr className="border-dashed border border-lightgrey my-4" />
                      <p className="font-bold text-sm text-text mb-1">
                        モチベーション
                      </p>
                      <StarRates rate={5} />
                    </div>
                  </div>
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-primary text-white text-center text-sm md:text-base">
                      ご担当者様
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">手軽さ</p>
                      <StarRates rate={4} />
                    </div>
                  </div>
                </div>
                <p className="flex-1 text-text">
                  参加者にクーポンを配布し、来店や購入の促進を目的とするキャンペーンです。
                  <br />
                  <br />
                  参加条件はフォロー＆リツイートです。手軽に参加できるので拡散性が高く、インスタントウィン形式は参加者のモチベーションを上げることができます。
                  <br />
                  <br />
                  実店舗やECサイトをお持ちのアカウントであれば、フォロワーの多少に関係なくおすすめのキャンペーンです。
                </p>
              </div>
            </div>
          </section>
          {/* 弊社でのキャンペーン実績 */}
          {/* <section className="campaign_results">
            <h2 className="title font-semibold text-center">弊社でのキャンペーン実績</h2>
            <div className="inner">
              <div className="left bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
              <div className="right bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
            </div>
          </section> */}
          {/* PARKLoTのキャンペーンツールを導入するとどう変わる？ */}
          <section className="before_after bg-secondary">
            <div className="inner pt-8 pb-10 md:py-20 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                PARKLoTのキャンペーン
                <br className="block md:hidden" />
                ツールを導入すると
                <br />
                どう変わる？
              </h2>
              <p className="mb-6 text-text">
                運用コストが大幅に削減できます。PARKLoTを導入すると、「当選者の決定・当選連絡のフロー」を効率化することが可能です。
                <br />
                さらに、今後のキャンペーン立案に役立つデータを蓄積することもできます。
              </p>
              <div className="flex flex-col md:flex-row md:gap-10 gap-4">
                <div className="flex-1 border border-primary rounded bg-white">
                  <h3 className="bg-primary font-bold text-xl leading-normal text-center text-white py-3">
                    手動でキャンペーンを行なった場合
                  </h3>
                  <div className="rounded-b p-4">
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      Twitterから該当キャンペーンページを開く
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      参加者一覧表示
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue">
                      <div className="flex justify-center items-center mb-2">
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          ユーザーの詳細画面確認
                        </div>
                        <div className="mx-2">
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="text-lightgrey text-base"
                          />
                        </div>
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          当選者決定
                        </div>
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        当選者の数だけ繰り返す
                      </p>
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue mb-4 text-center">
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3">
                        当選者アカウントを検索する
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3 mb-2">
                        DMの作成・確認・送信
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        当選者の数だけ繰り返す
                      </p>
                    </div>
                    <h3 className="text-center font-bold text-text mb-3">
                      キャンペーン終了後の作業だけで
                      <br className="block md:hidden" />
                      <span className="font-bold text-2xl text-accent">
                        3〜5時間必要
                      </span>
                    </h3>
                    <ul className="text-xs list-disc leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                      <li>
                        Twitterにログインし、
                        <span className="font-bold">
                          参加者のプロフィールを1件1件確認する
                        </span>
                        必要がある
                      </li>
                      <li>
                        当選者に
                        <span className="font-bold">1件1件DMを送る</span>
                        必要がある
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="border border-accent rounded md:mb-10 mb-4">
                    <h3 className="font-bold text-xl leading-normal bg-accent text-center text-white py-3">
                      PARKLoTを利用した場合
                    </h3>
                    <div className="bg-white rounded-b p-4">
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        キャンペーン情報をPARKLoTに提供、弊社にてデータ登録
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        キャンペーンが自動で開始、リツイートしたユーザを自動抽選
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center text-sm font-bold text-accent bg-accent_pale rounded leading-normal py-4 mb-4">
                        当選者がでると自動でクーポンURLを記載したDMを送信
                      </div>
                      <h3 className="text-center text-text font-bold mb-3">
                        キャンペーン実施の作業が
                        <br className="md:hidden block" />
                        <span className="text-2xl text-accent leading-normal">
                          20〜30分で完了！
                        </span>
                      </h3>
                      <ul className="text-xs list-disc text-text leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                        <li>
                          参加者一覧を、
                          <span className="font-bold">
                            ソート、フィルタリングの上CSVで確認
                          </span>
                          できる
                        </li>
                        <li>
                          当選・落選の
                          <span className="font-bold">
                            抽選作業を完全自動化
                          </span>
                          できる
                        </li>
                        <li>
                          クーポンの
                          <span className="font-bold">
                            発送の手間や印刷コストを削減
                          </span>
                          できる
                        </li>
                        <li>
                          （二次効果として）
                          <span className="font-bold">
                            応募者の傾向を分析、今後のキャンペーンに活かせる
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="bottom border border-lightgrey rounded">
                    <h3 className="text-xl font-bold bg-lightgrey leading-normal text-center text-white py-3">
                      以下の場合ツールの導入を
                      <br className="block md:hidden" />
                      おすすめしません
                    </h3>
                    <div className="bg-white rounded-b py-4 pr-4 pl-8">
                      <ul className="font-bold text-text list-disc leading-normal md:p-4 p-1">
                        <li>応募者の情報が不要</li>
                        <li>当選者数が少ない</li>
                        <li>ツール導入の費用がない</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
          {/* PARKLoTを使用した際の具体的なキャンペーン手順について */}
          <section className="usage_flow bg-white">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                PARKLoTを使用した際の
                <br />
                具体的なキャンペーン手順
                <br className="block md:hidden" />
                について
              </h2>
              <FlowNumber
                num="01"
                parklot={false}
                title="キャンペーン情報をPARKLoTにご提供ください"
              >
                <p className="text-text">
                  キャンペーン開始の３営業日前までに、キャンペーン情報をメールまたはSlackでご連絡下さい。
                  <br />
                  必要なデータは、
                  <a
                    className="text-accent underline"
                    href="https://www.notion.so/xhackttl/Ver-2-64d0b7b19a0548d09ec5323d1f9ce155"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【クーポン配布キャンペーン】スケジュールと入稿データ Ver.2
                  </a>
                  からご確認いただけます。
                </p>
              </FlowNumber>

              <FlowNumber
                num="02"
                parklot={true}
                title="キャンペーンの登録を行います"
              >
                <p className="text-text">
                  ご提供いただいた情報を元にデータを設定いたします。
                </p>
              </FlowNumber>

              <FlowNumber
                num="03"
                parklot={true}
                title="キャンペーンツイートが自動投稿され、キャンペーンが開始します"
              >
                <p className="text-text">
                  開始時間になると、ツイートが自動投稿され、キャンペーンが開始します。
                </p>
              </FlowNumber>

              <FlowNumber
                num="04"
                parklot={true}
                last={true}
                title="参加者にDMを自動送信します"
              >
                <p className="text-text">
                  リツイートユーザを収集し、当選確率を計算して自動で当落を決定します。当選者にはクーポンが表示できるURLをDMで自動送信します。
                  <br />
                  キャンペーン参加者の一覧は、管理画面から確認することができます。
                </p>
              </FlowNumber>
            </div>
          </section>
          {/* クーポンの種類は？ */}
          <section className="coupon bg-zinc-50">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                クーポンの種類は？
              </h2>
              <p className="text-text md:mb-10 mb-5">
                クーポンは、消込式・時限式・期間中無制限の３種類から選択が可能です。
              </p>
              <div className="p-4 text-center bg-white">
                <img
                  layout="constrained"
                  alt="インスタントウィンキャンペーン実施費用"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/case_study/coupon-type.png"
                  objectfit="contain"
                />
              </div>
            </div>
          </section>
          {/* PARKLoTの月額料金に含まれる機能は？ */}
          <section className="parklot_function bg-secondary">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                PARKLoTの月額料金に
                <br className="block md:hidden" />
                含まれる機能は？
              </h2>
              <p className="text-text md:mb-10 mb-5">
                クーポン配布キャンペーンは、月額料金内でご利用頂けます。
              </p>
              <h3 className="text-lg text-text font-bold text-center md:mb-6 mb-4">
                月額料金内に含まれる機能
              </h3>
              <div className="flex md:flex-row flex-col gap-4 md:gap-10 md:justify-around">
                <div className="p-4 md:p-6 text-center bg-white">
                  <img
                    layout="constrained"
                    alt="当選者の自動抽選"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/case_study/parklot_function01.png"
                    objectfit="contain"
                    className="mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text">当選者の自動抽選</h4>
                </div>
                <div className="p-4 md:p-6 text-center bg-white">
                  <img
                    layout="constrained"
                    alt="参加者情報のCSV出力"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/case_study/parklot_function02.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text mb-1">
                    参加者情報のCSV出力
                  </h4>
                  <p className="text-text text-xs">
                    *複雑なカスタマイズが必要な場合、
                    <br />
                    別途料金を頂く可能性がございます
                  </p>
                </div>
                <div className="p-4 md:p-6 text-center bg-white">
                  <img
                    layout="constrained"
                    alt="参加者に当落結果URLを自動DM送信"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/case_study/parklot_function03.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text">
                    参加者に当落結果URL
                    <br />
                    またはクーポンURLを
                    <br />
                    自動DM送信
                  </h4>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
        </main>
      </Layout>
    </>
  )
}

export default InstantWinCouponCampaign
